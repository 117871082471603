@import '@/theme/vars';

.social-community--button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-community--icons {
  ion-icon {
    transition: $TRANSITION_MAIN !important;
    &:hover {
      color: $PRIMARY;
    }
  }
}
