@import '@/theme/vars';

.footer {
  background-size: cover !important;
  background: url('/images/footer-bg.png') no-repeat right bottom !important;
  position: relative;
  padding: 50px 60px;
  max-width: 1440px;
  margin-left: auto !important;
  margin-right: auto !important;
  overflow-x: hidden;

  &--hr {
    position: relative;

    img {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 1440px !important;
    }
  }

  &--title {
    font-size: 20px !important;
  }

  .footer-button-link {
    display: flex;
    gap: 16px;

    .space-3-typography-secondary {
      transition: $TRANSITION_MAIN !important;
      text-align: right;
      display: block;
    }

    &:hover {
      .space-3-typography-secondary {
        color: $PRIMARY !important;
      }
    }
  }

  &--btn-join {
    max-width: 225px;
    background: transparent;
    border: 1px solid $PRIMARY;
    &:hover {
      span {
        color: black !important;
      }
    }
  }

  &--list-txt {
    color: #8fa4ba !important;
  }
  @media screen and (max-width: $MAX_WIDTH_MOBILE) {
    padding: 50px $PADDING_MOBILE;
  }
}
